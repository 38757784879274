import React from "react";
import { useSelector } from "react-redux";

const VentIndicator = () => {
  const { ventIndicatorCount } = useSelector((s) => s.game);

  return (
    <div id="vent-indicator" className="indicator" data-count={ventIndicatorCount} key={ventIndicatorCount}>
      <span className="material-symbols-outlined">
        hvac
      </span>
    </div>
  );
};

export default VentIndicator;
