import { aliveCrewInLocationSelector } from "~/selectors/locations";
import { clearActorAction, setActorAction } from "~/state/actors";
import { attack, wait, moveToLocation } from "~/actions/generators";
import { addLogEntry } from "~/actions/ui";
import { rndInt, rndArr } from "~/utils/rnd";

export default () => {
  return (dispatch, getState) => {
    //return;

    const { actors } = getState().actors;
    const { rooms } = getState().map;

    const alien = actors.find((a) => a.isAlien);
    const currentLocation = rooms[alien.location];

    // If we're attacking, keep at it.
    if (alien.currentAction?.action.type === 'attack') return;

    // TODO: If we're fleeing, choose a room or vent and move there.
    // Avoid areas with crew if possible.
    // Cooldown until we're no longer fleeing.

    // If crew in room, prepare to attack (cancel any move or wait actions).
    // Attack after a small delay (TODO: base on alien aggression).
    // TODO: Prefer scared people with crap/no weapons, avoid fire weapons.
    if (aliveCrewInLocationSelector(getState(), alien.location).length > 0) {
      if (alien.currentAction?.action.noCancel) return;

      if (['move', 'wait'].includes(alien.currentAction?.action.type)) {
        dispatch(clearActorAction(alien.index));
      }

      const delayedAction = wait(getState(), rndInt(2,4), () => {
        return (d, gs) => {
          const target = rndArr(aliveCrewInLocationSelector(gs(), alien.location));
          if (!target) return;

          const action = attack(gs(), alien.index, target.index);
          d(addLogEntry(target.index, `Under attack`, 'critical'));
          d(setActorAction({ actorId: alien.index, action }));
          return;
        };
      }, true); // noCancel

      dispatch(setActorAction({ actorId: alien.index, action: delayedAction }));
      return;
    }

    if (alien.currentAction) return;

    // TODO: Listen for creatures in nearby rooms.
    // TODO: prioritize door/vent noises
    const nearbyLocations = currentLocation.roomConnections.concat(currentLocation.deckConnections || []);

    // TODO: If something nearby, try to sense number of people in room.
    // If more than two in a nearby room, avoid by going elsewhere or waiting
    // Prioritise lone targets.
    // Move to selected room.

    // If nothing nearby, wait, or random wander.
    // TODO: look for sabotage opportunities in current room.
    // TODO: direct alien to general direction of crew a la Left 4 Dead
    const WAIT_CHANCE = 0.2;
    const MIN_WAIT_TIME = 8;
    const MAX_WAIT_TIME = 15;

    if (Math.random() <= WAIT_CHANCE) {
      const action = wait(getState(), rndInt(MIN_WAIT_TIME, MAX_WAIT_TIME));
      dispatch(setActorAction({ actorId: alien.index, action }));
      return;
    }

    const nextLocation = rndArr(nearbyLocations);
    const action = moveToLocation(getState(), alien.index, nextLocation);
    dispatch(setActorAction({ actorId: alien.index, action }));
    return;
  };
};
