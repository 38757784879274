import React, { useState } from "react";

const ScenarioView = ({ onStart }) => {
  const [selectedScenario, setSelectedScenario] = useState(null);

  const SCENARIOS = {
    short: {
      name: 'Short',
      features: {
        numCrew: 3,
        hasSynth: false,
        hasSupplies: true
      }
    },
    full: {
      name: 'Full',
      features: {
        numCrew: 6,
        hasSynth: true,
        hasSupplies: false
      }
    },
    custom: {
      name: 'Custom',
      isDisabled: true
    }
  };

  const renderScenarioOptions = () => {
    const scenarioTypes = Object.keys(SCENARIOS);
    return (
      <nav id="scenario-options">
        {scenarioTypes.map((s) => (
          <label className="flex-row" key={s}>
            <span>{SCENARIOS[s].name}</span>
            <input
              type="radio"
              name="scenarioSelect"
              value={s}
              checked={selectedScenario === s}
              onChange={() => { setSelectedScenario(s); }}
              disabled={SCENARIOS[s].isDisabled}
            />
          </label>
        ))}
      </nav>
    );
  };

  const renderScenarioDetails = () => {
    if (!selectedScenario) return null;

    const { features } = SCENARIOS[selectedScenario];

    return (
      <>
        <p>Unknown hostile lifeform on board</p>
        <p>{features.numCrew} of 7 crew members remain</p>
        <p>Ship's cat (Jones) on board</p>
        <p>Equipment {features.hasSupplies ? 'ready for use' : 'must be procured'}</p>
        {features.hasSynth && <p>Synthetic sleeper agent on board</p>}
      </>
    );
  };

  return (
    <div id="scenario-view" className="fade-in">
      <div>
        <h2>Choose Scenario</h2>
        <div id="scenario-view-selector" className="flex-row">
          {renderScenarioOptions()}
          <div id="scenario-details">
            {renderScenarioDetails()}
          </div>
        </div>
        <button
          className="action-item start-button"
          onClick={onStart}
          disabled={selectedScenario !== 'short'}
         >
          Start
        </button>
      </div>
    </div>
  );
};

export default ScenarioView;
