import { createSlice } from "@reduxjs/toolkit";

// game - global timers, UI, game scene etc.
const gameSlice = createSlice({
  name: 'game',
  initialState: {
    isStarted: false,
    tooh: 7000,
    log: [],
    doorIndicatorCount: 0,
    ventIndicatorCount: 0
  },
  reducers: {
    resetGameState: (state) =>{
      state.tooh = 7000;
      state.log = [];
      state.doorIndicatorCount = 0;
      state.ventIndicatorCount = 0;
      state.isStarted = true;
    },
    tick: (state) => {
      state.tooh = state.tooh - 1;
    },
    addLog: (state, { payload }) => {
      state.log = state.log.concat([payload]);
    },
    clearLog: (state) => {
      state.log = [];
    },
    pingDoorIndicator: (state) => {
      state.doorIndicatorCount += 1;
    },
    pingVentIndicator: (state) => {
      state.ventIndicatorCount += 1;
    }
  }
});

export const {
  resetGameState,
  tick,
  addLog,
  clearLog,
  pingDoorIndicator,
  pingVentIndicator
} = gameSlice.actions;

export default gameSlice;
