import React, { useEffect } from "react";
import screenfull from "screenfull";

const FullscreenToggleButton = () => {
  useEffect(() => {
    const scaleOnFullscreenChange = screenfull.on('change', () => {
      const targetEl = document.querySelector('#app');
      const { width, height } = targetEl.getBoundingClientRect();

      setTimeout(() => {
        // Calculate the aspect ratio and zoom proportionally
        // to the fullscreen size
        const scale = screenfull.isFullscreen
          ? Math.min(window.innerWidth/width, window.innerHeight/height)
          : 1;

        targetEl.style.zoom = scale;
      }, 100);
    });

    return () => {
      screenfull.off('change', scaleOnFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    const targetEl = document.querySelector('body');
    screenfull.toggle(targetEl);
  };

  return (
    <button
      id="fs"
      onClick={toggleFullscreen}
    >
      <span className="material-symbols-outlined">fullscreen</span>
    </button>
  );
};

export default FullscreenToggleButton;
