import { createSlice } from "@reduxjs/toolkit";
import MAP_ROOMS from '~/config/map';

// map - rooms, hilighted location, etc.
const mapSlice = createSlice({
  name: 'map',
  initialState: {
    decks: {
      upper: {
        name: 'Upper Deck'
      },
      middle: {
        name: 'Middle Deck'
      },
      lower: {
        name: 'Lower Deck'
      }
    },
    targetLocation: null,
    rooms: {
      ...MAP_ROOMS
    }
  },
  reducers: {
    setTargetLocation: (state, { payload }) => {
      state.targetLocation = payload;
    },
    clearTargetLocation: (state) => {
      state.targetLocation = null;
    },
    setVentStatus: (state, { payload }) => {
      const { locationId, isVentOpen } = payload;
      state.rooms[locationId].isVentOpen = isVentOpen;
    },
    addItemToLocation: (state, { payload }) => {
      const { locationId, itemId } = payload;
      if (state.rooms[locationId].items === undefined) state.rooms[locationId].items = [];
      state.rooms[locationId].items.push(itemId);
    },
    removeItemFromLocation: (state, { payload }) => {
      const { locationId, itemId } = payload;
      state.rooms[locationId].items = state.rooms[locationId].items.filter((i) => i !== itemId);
    }
  }
});

export const {
  setTargetLocation,
  clearTargetLocation,
  setVentStatus,
  addItemToLocation,
  removeItemFromLocation
} = mapSlice.actions;

export default mapSlice;
