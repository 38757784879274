import React from "react";
import { useSelector } from "react-redux";
import ActionItem from "~/components/action-item";
import { actionListSelector } from "~/selectors/action-list";

const ActionsPanel = () => {
  const actionList = useSelector(actionListSelector);

  return (
    <div id="actions-panel" className="scroll-panel">
      {actionList.map((action, idx) => (
        <ActionItem action={action} key={idx} />
      ))}
    </div>
  );
};

export default ActionsPanel;
