import { createSlice } from "@reduxjs/toolkit";
import GAME_ACTORS from '~/config/actors';

// actors - active crew member, all crew & npc locations, stats, inventory, etc.
const actorsSlice = createSlice({
  name: 'actors',
  initialState: {
    activeCrewIndex: 0,
    actors: [
      ...GAME_ACTORS.map((a) => (
        {
          ...a,
          inventory: [null, null] // inventory slots per actor
        }
      ))
    ]
  },
  reducers: {
    setActiveCrewIndex: (state, { payload }) => {
      state.activeCrewIndex = payload;
    },
    setActorLocation: (state, { payload }) => {
      const { actorId, locationId } = payload;
      state.actors[actorId].location = locationId;
    },
    setActorAction: (state, { payload }) => {
      const { actorId, action } = payload;
      state.actors[actorId].currentAction = {
        action,
        ticks: action.ticks
      };
    },
    advanceActorAction: (state, { payload }) => {
      state.actors[payload].currentAction.ticks -= 1;
    },
    clearActorAction: (state, { payload }) => {
      state.actors[payload].currentAction = null;
    },
    giveItemToActor: (state, { payload }) => {
      const { actorId, itemId } = payload;
      const freeSlot = state.actors[actorId].inventory.findIndex((i) => i === null);
      if (freeSlot === -1) {
        throw 'giveItemToActor: no free slot available';
        return;
      }
      state.actors[actorId].inventory[freeSlot] = itemId;
    },
    takeItemFromActor: (state, { payload }) => {
      const { actorId, itemId } = payload;
      const itemIndex = state.actors[actorId].inventory.findIndex((i) => i === itemId);
      state.actors[actorId].inventory[itemIndex] = null;
    },
    setJonesCaptured: (state, { payload }) => {
      const jones = state.actors.find((i) => i.isCat);
      jones.currentAction = null;
      jones.isCaptured = true;
      jones.capturedInItem = payload;
    },
    damageActor: (state, { payload }) => {
      const { actorId, amount } = payload;
      state.actors[actorId].health = Math.max(0, state.actors[actorId].health - amount);
    },
    swapActorInventory: (state, {payload}) => {
      state.actors[payload].inventory = [...state.actors[payload].inventory].reverse();
    }
  }
});

export const {
  setActiveCrewIndex,
  setActorLocation,
  setActorAction,
  advanceActorAction,
  clearActorAction,
  giveItemToActor,
  takeItemFromActor,
  setJonesCaptured,
  damageActor,
  swapActorInventory
} = actorsSlice.actions;

export default actorsSlice;
