import { crewListSelector } from '~/selectors/crew';
import SoundPlayer from '~/utils/sound-player';
import { pingDoorIndicator, pingVentIndicator, addLog } from "~/state/game";

export const pingDoor = () => {
  return (dispatch, getState) => {
    dispatch(pingDoorIndicator());
    SoundPlayer.play('door');
  };
};

export const pingVent = () => {
  return (dispatch, getState) => {
    dispatch(pingVentIndicator());
    SoundPlayer.play('vent');
  };
};

export const addLogEntry = (actorId, message, type = 'info') => {
  return (dispatch, getState) => {
    const msg = {
      actorId,
      message,
      type
    };
    dispatch(addLog(msg));
  };
};

let alienAlertSound = null;

export const playPingSounds = () => {
  return (dispatch, getState) => {
    // Tracker ping sfx
    const crew = crewListSelector(getState());
    const isTrackerPinging = crew.filter((c) => c.seesTrackerPing).length > 0;
    if (isTrackerPinging) {
      SoundPlayer.play('tracker');
    }

    // Alien alert sfx
    const isAlienSeen = crew.filter((c) => c.seesAlien).length > 0;
    if (isAlienSeen) {
      if (alienAlertSound === null) {
        alienAlertSound = SoundPlayer.play('alarm2', 0, true);
      }
    } else {
      if (alienAlertSound !== null) {
        alienAlertSound.stop();
        alienAlertSound = null;
      }
    }
  };
};
