import React from "react";
import { useSelector } from "react-redux";
import GamePanel from "~/components/game-panel";
import CrewPanel from "~/components/crew-panel";
import LogPanel from "~/components/log-panel";
import ActiveCrewPanel from "~/components/active-crew-panel";
import ActionsPanel from "~/components/actions-panel";
import MapDisplay from "~/components/map-display";

const MainGameView = () => {
  const { isStarted } = useSelector((s) => s.game);

  if (!isStarted) return null;

  return (
    <>
      <GamePanel />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between'}}>
        <div>
          <CrewPanel />
          <LogPanel />
        </div>
        <div style={{ marginLeft: '1em', marginRight: '1em' }}>
          <ActiveCrewPanel />
          <ActionsPanel />
        </div>
        <MapDisplay />
      </div>
    </>
  );
};

export default MainGameView;
