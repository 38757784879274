// Map an an object of objects and spreads the return from fn() in each.
// fn() takes k, v as params.
export const mixinNestedObjects = (obj, fn) => Object.entries(obj).reduce((newObj, [id, o]) => {
  newObj[id] = {
    ...o,
    ...fn(id, o)
  };
  return newObj;
}, {});

// Adds an 'id' field with key for an object of objects.
export const mixinNestedObjectIDs = (obj) => mixinNestedObjects(obj, (id, o) => ({ id }));

// Adds an 'index' field with numeric index for an array of objects.
export const mixinCollectionIndexes = (arr, key = 'index') => arr.map((item, idx) => ({ ...item, [key]: idx }));
