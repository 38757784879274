import { setActorLocation } from '~/state/actors';
import { pingDoor, addLogEntry } from './ui';
import { doDamage } from './actors';

export const moveToLocation = (state, actorId, locationId, isDeckChange) => {
  const targetLocation = state.map.rooms[locationId];
  const actor = state.actors.actors[actorId];
  const ticks = (actor.moveSpeed || 5) + (isDeckChange ? 2 : 0);

  return {
    name: targetLocation.name,
    type: isDeckChange ? 'deck' : 'move',
    targetLocation: locationId,
    isActive: true,
    ticks: ticks,
    onActionComplete: (actorId) => {
      return (dispatch, getState) => {
        if (!isDeckChange) dispatch(pingDoor());
        if (actor.isCrew) dispatch(addLogEntry(actorId, `Moved to ${getState().map.rooms[locationId].name}`));
        dispatch(setActorLocation({ actorId, locationId }));
      };
    }
  };
};

export const wait = (state, ticks, then, noCancel = false) => {
  return {
    type: 'wait',
    isActive: true,
    ticks,
    noCancel,
    onActionComplete: () => {
      return (dispatch, getState) => {
        if (typeof then !== 'function') return;
        dispatch(then());
      };
    }
  };
};

export const attack = (state, attackerId, targetId) => {
  const amount = 1;

  const validate = (state) => {
    const { actors } = state.actors;
    const attacker = actors[attackerId];
    const target = actors[targetId];
    return target.location === attacker.location && target.health > 0;
  };

  return {
    name: 'Attack',
    type: 'attack',
    target: targetId,
    isActive: true,
    ticks: 3,
    validate,
    onActionComplete: () => {
      return (dispatch, getState) => {
        dispatch(doDamage(targetId, amount));
      }
    }
  }
};
