import React from "react";
import { useSelector } from "react-redux";
import { crewListSelector } from "~/selectors/crew";
import CharacterStatus from "~/components/character-status";

const CrewPanel = () => {
  const crewList = useSelector(crewListSelector);

  return (
    <div id="crew-panel">
      {crewList.map(({ index, name, health, will, location, locationName, isActive, currentAction, seesCat, seesCatHiss, seesTrackerPing, seesAlien }) => (
        <CharacterStatus
          key={index}
          id={index}
          name={name}
          health={health}
          will={will}
          location={location}
          locationName={locationName}
          isActive={isActive}
          currentAction={currentAction}
          seesCat={seesCat}
          seesCatHiss={seesCatHiss}
          seesTrackerPing={seesTrackerPing}
          seesAlien={seesAlien}
        />
      ))}
    </div>
  );
};

export default CrewPanel;
