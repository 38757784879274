import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SoundPlayer from "~/utils/sound-player";
import { issueAction } from "~/actions/actors";
import { setTargetLocation, clearTargetLocation } from "~/state/map";

const ActionItem = ({ action }) => {
  const { activeCrewIndex } = useSelector((s) => s.actors);
  const dispatch = useDispatch();

  const { name, type, isActive, description, targetLocation } = action;

  const doAction = () => {
    SoundPlayer.play('select');
    dispatch(issueAction(activeCrewIndex, action));
  };

  const doMouseEnter = (e) => {
    SoundPlayer.play('hover');
  };

  // Highlight target location when hovering movement actions
  const doMouseOver = (e) => {
    if (action.type !== 'move') return;
    dispatch(setTargetLocation(targetLocation));
  };

  const doMouseOut = () => {
    if (action.type !== 'move') return;
    dispatch(clearTargetLocation());
  }

  return (
    <button
      className="action-item flex-row"
      data-type={type}
      disabled={!isActive}
      title={description}
      onClick={doAction}
      onMouseEnter={doMouseEnter}
      onMouseOver={doMouseOver}
      onMouseOut={doMouseOut}
    >
      {type === 'catch' && <span className="material-symbols-outlined action-icon">place_item</span>}
      {type === 'move' && <span className="material-symbols-outlined action-icon">directions_run</span>}
      {type === 'vent' && <span className="material-symbols-outlined action-icon">hvac</span>}
      {type === 'pickup' && <span className="material-symbols-outlined action-icon">upload</span>}
      {type === 'drop' && <span className="material-symbols-outlined action-icon">download</span>}
      {type === 'deck' && <span className="material-symbols-outlined action-icon">expand</span>}
      {type === 'swap' && <span className="material-symbols-outlined action-icon">cached</span>}
      <span>{name}</span>
    </button>
  );
};

export default ActionItem;
