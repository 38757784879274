import { addLogEntry, pingVent } from "~/actions/ui";
import { giveItemToActor, takeItemFromActor, swapActorInventory } from "~/actions/actors";
import { moveToLocation } from "~/actions/generators";
import { giveItemToActor, takeItemFromActor, swapActorInventory } from "~/state/actors";
import { setVentStatus, removeItemFromLocation, addItemToLocation } from "~/state/map";
import { hasItemEquipped } from "~/utils/inventory";
import { IV_NET, ITEMS, ITEM_TYPES } from "~/config/items";
import { activeCrewSelector } from "./crew";
import { actorRoomSelector } from "./locations";

// Returns all valid actions for the active crew member,
// based on their location, inventory, status of NPCs, etc.
export const actionListSelector = (state) => {
  const activeCrew = activeCrewSelector(state);
  const { location } = activeCrew;
  const rooms = state.map.rooms;
  const currentLocation = rooms[location];

  const actions = [];

  // Specials
  // TODO: attack with weapon, open airlocks, scuttle ship, throw net, repair, etc...

  // Capture Jones (if he's roaming here and we're holding a net)
  const captureJonesValidation = (state) => {
    const { actors } = state.actors;
    const captor = actors[activeCrew.index];
    const jonesy = actors.find((a) => a.isCat);
    return hasItemEquipped(captor, IV_NET) && !jonesy.isCaptured && jonesy.location === captor.location;
  };

  if (captureJonesValidation(state)) {
    const catchAction = {
      name: 'Catch Jones',
      type: 'catch',
      isActive: true,
      ticks: 4,
      validate: captureJonesValidation,
      onActionComplete: (actorId) => {
        return (dispatch, getState) => {
          dispatch(addLogEntry(activeCrew.index, 'Captured Jones', 'notify'));
          dispatch(captureJones());
        };
      }
    };

    actions.push(catchAction);
  }

  // Change decks
  currentLocation.deckConnections?.forEach((locationId) => {
    const action = moveToLocation(state, activeCrew.index, locationId, true);

    actions.push(action);
  });

  // Move to location(s)
  currentLocation.roomConnections.forEach((locationId) => {
    const action = moveToLocation(state, activeCrew.index, locationId);

    actions.push(action);
  });

  // Vent open/close
  const { isVentOpen } = currentLocation;

  const ventAction = {
    name: isVentOpen ? 'Close Vent' : 'Open vent',
    type: 'vent',
    isActive: true,
    ticks: 3,
    validate: (state) => state.map.rooms[location].isVentOpen === isVentOpen,
    onActionComplete: (actorId) => {
      return (dispatch, getState) => {
        dispatch(pingVent());
        dispatch(addLogEntry(activeCrew.index, `${isVentOpen ? 'Closed' : 'Opened'} vent`, 'notify'));
        dispatch(setVentStatus({ locationId: location, isVentOpen: !isVentOpen }));
      };
    }
  };

  actions.push(ventAction);

  // Inventory get, if items are present and slots are free
  if (currentLocation.items?.length > 0 && activeCrew.inventory.includes(null)) {
    currentLocation.items.forEach((itemId) => {
      const item = ITEMS[itemId];
      const itemData = ITEM_TYPES[item.type];
      const pickupAction = {
        name: `Get ${itemData.name}`,
        type: 'pickup',
        isActive: true,
        ticks: 3,
        validate: (state, actorId) => {
          const room = actorRoomSelector(state, actorId);
          return room.items.includes(itemId) && state.actors.actors[actorId].inventory.includes(null);
        },
        onActionComplete: (actorId) => {
          return (dispatch, getState) => {
            dispatch(removeItemFromLocation({ locationId: location, itemId }));
            dispatch(giveItemToActor({ actorId, itemId }));
            dispatch(addLogEntry(actorId, `Got ${itemData.name}`, 'notify'));
          };
        }
      };

      actions.push(pickupAction);
    });
  }
  const activeCrewItems = activeCrew.inventory.filter((i) => i !== null);

  if (activeCrewItems.length > 0) {
    // Inventory drop, if carrying items
    activeCrewItems.forEach((itemId) => {
      const item = ITEMS[itemId];
      const itemData = ITEM_TYPES[item.type];
      const dropAction = {
        name: `Drop ${itemData.name}`,
        type: 'drop',
        isActive: true,
        ticks: 2,
        onActionComplete: (actorId) => {
          return (dispatch, getState) => {
            dispatch(takeItemFromActor({ actorId, itemId }));
            dispatch(addItemToLocation({ locationId: location, itemId }));
            dispatch(addLogEntry(actorId, `Dropped ${itemData.name}`, 'notify'));
          };
        }
      };

      actions.push(dropAction);
    });

    // Inventory swap, if carrying items
    const swapAction = {
      name: 'Swap Equipped',
      type: 'swap',
      isActive: true,
      ticks: 2,
      onActionComplete: (actorId) => {
        return (dispatch, getState) => {
          dispatch(swapActorInventory(actorId));
        };
      }
    };
    actions.push(swapAction);
  }

  return actions;
};
