import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";

import gameSlice from "~/state/game";
import mapSlice from "~/state/map";
import actorsSlice from "~/state/actors";

import FullscreenToggleButton from "~/components/full-screen-toggle-button";
import StartGameView from "~/components/start-game-view";
import MainGameView from "~/components/main-game-view";

import SoundPlayer from "~/utils/sound-player";

// Store setup

const store = configureStore({
  reducer: {
    game: gameSlice.reducer,
    map: mapSlice.reducer,
    actors: actorsSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

// App

const App = () => (
  <Provider store={store}>
    <FullscreenToggleButton />
    <StartGameView />
    <MainGameView />
  </Provider>
);

ReactDOM.render(<App />, document.querySelector('#game'));

// Start the audio engine after user interaction
document.querySelector('body').addEventListener('click', () => {
  SoundPlayer.getContext().resume();
});

