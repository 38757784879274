import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

const LogPanel = () => {
  const { actors } = useSelector((s) => s.actors);
  const { log } = useSelector((s) => s.game);
  const scrollEl = useRef(null);

  useEffect(() => {
    scrollEl.current.scrollTop = scrollEl.current.scrollHeight;
  }, [log]);

  return (
    <div id="log-panel" className="scroll-panel" ref={scrollEl}>
      {log.map(({ actorId, type, message }) => (
        <p data-type={type} data-actor={actorId}>
          {actorId !== null && <span>{actors[actorId].name}: </span>}
          {message}
        </p>
      ))}
      <p id="log-bottom">>_</p>
    </div>
  );
};

export default LogPanel;
