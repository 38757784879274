import {
  clearActorAction,
  advanceActorAction,
  setActorAction,
  setJonesCaptured,
  damageActor,
  takeItemFromActor,
  setActiveCrewIndex
} from "~/state/actors";
import { addItemToLocation } from "~/state/map";
import { isValidAction } from "~/utils/actions";
import { IV_NET } from "~/config/items";
import { crewListSelector } from "~/selectors/crew";
import { addLogEntry } from "./ui";

export const advanceActors = () => {
  return (dispatch, getState) => {
    // Loop all actors and update their actions
    let actorIds = getState().actors.actors.map((a) => a.index);

    actorIds.forEach((idx) => {
      const a = getState().actors.actors[idx];

      if (!a.currentAction) return;

      if (a.health === 0 || !isValidAction(getState(), a.currentAction.action, idx)) {
        // TODO: flag a cancelled/invalid action in the UI
        dispatch(clearActorAction(idx));
        return;
      }
      dispatch(advanceActorAction(idx));
    });

    // Fire any ready actions
    actorIds.forEach((idx) => {
      const a = getState().actors.actors[idx];
      if (!a.currentAction) return;

      if (a.health === 0) {
        dispatch(clearActorAction(idx));
        return;
      }

      if (a.currentAction.ticks === 0) {
        if (a.currentAction.action.onActionComplete && isValidAction(getState(), a.currentAction.action, idx)) {
          dispatch(a.currentAction.action.onActionComplete(idx));
        }
        // Special handling for when onActionComplete sets a new action
        const ax = getState().actors.actors[idx];
        if (ax.currentAction.ticks === 0) dispatch(clearActorAction(idx));
      }
    });
  };
};

export const doDamage = (actorId, amount) => {
  return (dispatch, getState) => {
    dispatch(damageActor({ actorId, amount }));

    const { actors, activeCrewIndex } = getState().actors;
    const targetActor = actors[actorId];

    if (targetActor.health > 0) {
      if (targetActor.isCrew) {
        dispatch(addLogEntry(actorId, `Wounded`, 'critical'));
      }
      return;
    }

    // Target was killed
    dispatch(clearActorAction(targetActor.index));

    if (targetActor.isAlien) {
      // TODO: Alien killed, game won
      return;
    }

    if (targetActor.isCrew) {
      dispatch(addLogEntry(targetActor.index, '---', 'critical'));
      dispatch(addLogEntry(targetActor.index, '** SIGNAL LOST **', 'critical'));

      // Drop their items
      const items = targetActor.inventory.filter((i) => i !== null);
      items.forEach((itemId) => {
        dispatch(takeItemFromActor({ actorId: targetActor.index, itemId }));
        dispatch(addItemToLocation({ locationId: targetActor.location, itemId }));
      });

      // Any crew left?
      const crewAlive = crewListSelector(getState()).filter((a) => a.health > 0);
      if (crewAlive.length === 0) {
        // TODO: All crew lost, game over
        dispatch(setActiveCrewIndex(null));
        return;
      }

      // Select the first living crew member left
      if (activeCrewIndex === targetActor.index) dispatch(setActiveCrewIndex(crewAlive[0].index));
      return;
    }

    throw `Actor ${actorId} was killed, neither crew nor Alien`;
  };
};

export const captureJones = () => {
  return (dispatch, getState) => {
    dispatch(setJonesCaptured(IV_NET));
  };
};

export const issueAction = (actorId, action) => {
  return (dispatch, getState) => {
    dispatch(setActorAction({ actorId, action }));
  };
};
