import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { addLogEntry } from "~/actions/ui";
import { setActiveCrewIndex } from "~/state/actors";
import { setTargetLocation, clearTargetLocation } from "~/state/map";
import { rndArr } from "~/utils/rnd";
import SoundPlayer from "~/utils/sound-player";
import ProgressBar from "~/components/progress-bar";

const CharacterStatus = (
  { id, name, health, will, location, locationName, isActive, currentAction, seesCat, seesCatHiss, seesTrackerPing, seesAlien }
) => {
  const catHissRef = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (seesCatHiss && !catHissRef.current) {
      const msg = rndArr([
        'Jones looks uneasy',
        'Jones is hissing',
        'Jones seems afraid'
      ]);
      dispatch(addLogEntry(index, msg, 'warning'));
    }
    catHissRef.current = seesCatHiss;
  }, [seesCatHiss]);

  const setActive = () => {
    if (health === 0) return;
    dispatch(setActiveCrewIndex(id));
  };

  const doMouseEnter = (e) => {
    SoundPlayer.play('hover');
  };

  const doMouseOver = () => {
    dispatch(setTargetLocation(location));
  };

  const doMouseOut = () => {
    dispatch(clearTargetLocation());
  };

  return (
    <button
      className="char-status"
      data-active={isActive}
      data-name={name}
      data-health={health}
      onClick={setActive}
      onMouseEnter={doMouseEnter}
      onMouseOver={doMouseOver}
      onMouseOut={doMouseOut}
    >
      <div className="char-stats">
        <ProgressBar max={3} value={health} className="char-health-bar" />
        <ProgressBar max={3} value={health > 0 ? will : 0} className="char-will-bar" />
      </div>
      <div className="char-img" data-action={currentAction ? currentAction.type : null}></div>
      <div style={{ flexGrow: 1 }}>
        <h3>
          { name }
        </h3>
        {!currentAction && <span>{locationName}</span>}
        {currentAction && <div style={{ display: 'flex', alignItems: 'center' }}>
          <ProgressBar max={currentAction.action.ticks} value={currentAction.ticks} className="action-progress"></ProgressBar>
        </div>}
      </div>
      {health > 0 && !currentAction && (
      <div className="alert-icons">
        {seesAlien && <span className="material-symbols-outlined sees-alien-icon">emergency_home</span>}
        {!seesAlien && seesTrackerPing && <span className="material-symbols-outlined sees-tracker-icon">wifi_tethering</span>}
        {!seesAlien && seesCatHiss && <span className="material-symbols-outlined sees-cat-hiss-icon">pets</span>}
        {!seesAlien && seesCat && <span className="material-symbols-outlined sees-cat-icon">pets</span>}
      </div>
      )}
    </button>
  );
};

export default CharacterStatus;
