import { rndInt, rndArr } from '~/utils/rnd';
import { hasItem } from '~/utils/inventory';
import { IV_NET } from '~/config/items';
import { wait, moveToLocation } from '~/actions/generators';
import { setActorAction } from '~/state/actors';

export default () => {
  return (dispatch, getState) => {
    const { actors } = getState().actors;
    const { rooms } = getState().map;

    const cat = actors.find((a) => a.isCat);
    const currentLocation = rooms[cat.location];

    const WAIT_CHANCE = 0.4;
    const MIN_WAIT_TIME = 8;
    const MAX_WAIT_TIME = 20;

    // Keep Jones in the net if he's captured there
    // TODO: clean this up, add box & ensure correct usage
    if (cat.isCaptured) {
      const actorWithNet = actors.find((a) => hasItem(a, IV_NET));

      if (actorWithNet) {
        cat.location = actorWithNet.location;
      } else {
        const netLocationId = Object.entries(rooms).find(([k, v]) => v.items?.includes(IV_NET))[0];

        if (netLocationId) {
          cat.location = netLocationId;
        } else {
          throw 'FATAL: net not in location or with actor';
        }
      }

      // TODO: hiss if the alien is in an adjacent room

      return;
    }

    if (cat.currentAction) return;

    // Random chance to stay in one place for a bit
    if (Math.random() <= WAIT_CHANCE) {
      const action = wait(getState(), rndInt(MIN_WAIT_TIME, MAX_WAIT_TIME));
      dispatch(setActorAction({ actorId: cat.index, action }));
      return;
    }

    // Random move to an adjacent location
    // (Jonesy can't change decks)
    const nextLocation = rndArr(currentLocation.roomConnections);
    const action = moveToLocation(getState(), cat.index, nextLocation);
    dispatch(setActorAction({ actorId: cat.index, action }));
    return;
  }
};
