import React from "react";
import { useSelector } from "react-redux";

const DoorIndicator = () => {
  const { doorIndicatorCount } = useSelector((s) => s.game);

  return (
    <div id="door-indicator" className="indicator" data-count={doorIndicatorCount} key={doorIndicatorCount}>
      <span className="material-symbols-outlined">
        door_sliding
      </span>
    </div>
  );
};

export default DoorIndicator;
