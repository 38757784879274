const actx = new AudioContext();
if (!actx) console.warn('Web Audio not supported or received an error!');

const SoundPlayer = ((AC) => {

  const soundBank = {};
  let currentContext = AC;

  const setContext = (context) => {
    currentContext = context;
  };
  const getContext = () => (currentContext);

  // Load an object manifest of id-url pairs for sound samples.
  // Returns a promise that resolves when all samples loaded
  const load = (manifest) => {
    const total = Object.keys(manifest).length;

    return new Promise((resolve) => {
      let count = 0;

      for(let k in manifest) {
        fetch(manifest[k])
          .then((res) => res.arrayBuffer())
          .then((data) => {
            currentContext.decodeAudioData(data, (decodedData) => {
              soundBank[k] = decodedData;
              if (++count === total) resolve(soundBank); // TODO: add progress callback
            });
        });
      }
    });
  };

  // Play the sound with the given id, now, or optionally later.
  const play = (id, time = 0, loop = false) => {
    const s = currentContext.createBufferSource();
  	s.buffer = soundBank[id];
  	s.connect(currentContext.destination);
  	s.start(time);
    s.loop = loop;
    return s;
  };

  // Public API
  return {
    load,
    play,
    setContext,
    getContext
  };

})(actx);

export default SoundPlayer;
