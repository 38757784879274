import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { activeCrewSelector } from "~/selectors/crew";
import { ITEM_TYPES, ITEMS, IT_TRACKER } from "~/config/items";
import SoundPlayer from "~/utils/sound-player";
import ProgressBar from "~/components/progress-bar";

const ActiveCrewPanel = () => {
  const { rooms } = useSelector((s) => s.map);
  const { index, name, location, locationName, health, will, currentAction, inventory, seesTrackerPing, seesCatHiss, seesAlien } = useSelector(activeCrewSelector);
  const { isVentOpen } = rooms[location];
  const alienSound = useRef(null);

  useEffect(() => {
    if (seesAlien) {
      alienSound.current = SoundPlayer.play('alarm1', 0, true);
    } else {
      alienSound.current?.stop();
    }
  }, [seesAlien]);

  return (
    <div id="active-crew-panel" data-name={name}>
      <h2>{name}</h2>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'stretch' }}>
        <div className="char-img" data-action={currentAction ? currentAction.action.type : null}></div>
        <div style={{ flexGrow: 1 }}>
          {!currentAction && (
            <span>
              {locationName}<br />
              Integrity: 100%<br />
              Vent: <span data-vent-open={isVentOpen}>{isVentOpen ? 'OPEN' : 'CLOSED'}</span>
            </span>
          )}
          {currentAction && <span>{currentAction.action.type}</span>}
          {currentAction && <div style={{ display: 'flex', alignItems: 'center' }}>
            <ProgressBar max={currentAction.action.ticks} value={currentAction.ticks} className="action-progress"></ProgressBar>
          </div>}
        </div>
      </div>
      <div className="char-stats">
        <div className="flex-row">
          <span>VITALS&nbsp;</span> <ProgressBar max={3} value={health} className="char-health-bar" />
        </div>
        <div className="flex-row">
          <span>MORALE&nbsp;</span> <ProgressBar max={3} value={will} className="char-will-bar" />
        </div>
      </div>
      <div className="char-inv">
        {inventory.map((id, idx) => (
          <div className="char-inv-slot flex-row" data-slot={idx} data-item={id} key={idx}>
            <span className="inv-item-name">
              {idx === 0 && <span className="inv-item-equip"><strong>E</strong></span>}
              {id === null
                ? '[NO ITEM]'
                : `${ITEM_TYPES[ITEMS[id].type].name}`}
            </span>
            <span className="inv-item-icons">
              {id !== null && ITEMS[id].type === IT_TRACKER && seesTrackerPing && <span className="material-symbols-outlined sees-tracker-icon">wifi_tethering</span>}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActiveCrewPanel;
