import { mixinNestedObjectIDs } from "~/utils/structs";

// Inventory item types & details

export const IT_CATBOX = "catBox";
export const IT_TRACKER = "tracker";
export const IT_INCINERATOR = "incinerator";
export const IT_NET = "net";
export const IT_HARPOON = "harpoon";
export const IT_SPANNER = "spanner";

export const ITEM_TYPES = {
  [IT_CATBOX]: {
    name: 'Cat Box',
  },
  [IT_TRACKER]: {
    name: 'M-Tracker'
  },
  [IT_INCINERATOR]: {
    name: 'Incinerator'
  },
  [IT_NET]: {
    name: 'Net'
  },
  [IT_HARPOON]: {
    name: 'Harpoon Gun'
  },
  [IT_SPANNER]: {
    name: 'Spanner'
  },
};

// Individual item instances

export const IV_CATBOX = 'catBox';
export const IV_TRACKER1 = 'tracker1';
export const IV_TRACKER2 = 'tracker2';
export const IV_INCINERATOR1 = 'incinerator1';
export const IV_INCINERATOR2 = 'incinerator2';
export const IV_INCINERATOR3 = 'incinerator3';
export const IV_NET = 'net';
export const IV_HARPOON = 'harpoon';
export const IV_SPANNER1 = 'spanner1';
export const IV_SPANNER2 = 'spanner2';

export const ITEMS = mixinNestedObjectIDs({
  [IV_CATBOX]: {
    type: IT_CATBOX
  },
  [IV_TRACKER1]: {
    type: IT_TRACKER
  },
  [IV_TRACKER2]: {
    type: IT_TRACKER
  },
  [IV_INCINERATOR1]: {
    type: IT_INCINERATOR
  },
  [IV_INCINERATOR2]: {
    type: IT_INCINERATOR
  },
  [IV_INCINERATOR3]: {
    type: IT_INCINERATOR
  },
  [IV_NET]: {
    type: IT_NET
  },
  [IV_HARPOON]: {
    type: IT_HARPOON
  },
  [IV_SPANNER1]: {
    type: IT_SPANNER
  },
  [IV_SPANNER2]: {
    type: IT_SPANNER
  }
});
