import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SoundPlayer from "~/utils/sound-player";
import SOUND_MANIFEST from "~/config/sounds";
import ScenarioView from "~/components/scenario-view";
import { startGame } from "~/actions/game";

const StartGameView = () => {
  const { isStarted } = useSelector((s) => s.game);
  const dispatch = useDispatch();
  const [isLoaded, setLoaded] = useState(false);
  const [isScenarioViewShown, setScenarioViewShown] = useState(false);

  useEffect(() => {
    SoundPlayer.load(SOUND_MANIFEST).then(() => {
      setLoaded(true);
    });
  }, []);

  const showOptions = () => {
    setScenarioViewShown(true);
    SoundPlayer.play('click');
  };

  const start = () => {
    dispatch(startGame());
    SoundPlayer.play('ambience', 0, true);
  };

  if (isStarted) return null;

  return (
    <div id="start-view" className="fade-in">
      {isLoaded
        ? <>
          <h1>A L I E N</h1>
          <button
            className="action-item start-button"
            onClick={showOptions}
            disabled={isScenarioViewShown}
            style={{ visibility: isScenarioViewShown ? 'hidden' : 'visible' }}
          >
            Start
          </button>
        </>
        : <span>Loading...</span>}
      {isScenarioViewShown && <ScenarioView onStart={start} />}
    </div>
  );
};

export default StartGameView;
