import { IT_TRACKER } from "~/config/items";
import { hasItem, hasItemEquipped, hasItemTypeEquipped } from "~/utils/inventory";
import { actorsInLocationSelector } from "./locations";

// Returns an array of crew member objects.
export const crewSelector = (state) => {
  const { actors } = state.actors;
  return actors.filter((i) => i.isCrew);
};

// Returns all crew members, decorated with the data required to render the crew list.
export const crewListSelector = (state) => {
  const crew = crewSelector(state);
  const { activeCrewIndex, actors } = state.actors;
  const rooms = state.map.rooms;

  return crew.map((c, idx) => {
    const currentLocation = rooms[c.location];
    const jones = actors.find((a) => a.isCat);
    const hasCat = jones.isCaptured && c.health > 0 && hasItem(c, jones.capturedInItem);
    const seesCat = !jones.isCaptured && c.health > 0 && actorsInLocationSelector(state, c.location).filter((a) => a.isCat).length > 0;

    // Jones hisses if alien is in an adjacent room while equipped (captured)
    const seesCatHiss = hasCat && hasItemEquipped(c, jones.capturedInItem) && rooms[c.location].roomConnections.map(
      (roomId) => actors.filter(
        (a) => a.health > 0 && a.location === roomId && a.isAlien
      ).length > 0
    ).includes(true);

    // Tracker pings if ANY creature is in an adjacent room while equipped
    const seesTrackerPing = c.health > 0
      && hasItemTypeEquipped(c, IT_TRACKER)
      && rooms[c.location].roomConnections.map(
        (roomId) => actors.filter(
          (a) => a.health > 0 && a.location === roomId && !(a.isCat && a.isCaptured)
        ).length > 0
      ).includes(true)

    const seesAlien = c.health > 0 && actorsInLocationSelector(state, c.location).filter((a) => a.isAlien).length > 0;

    const crewDecorated = {
      ...c,
      locationName: currentLocation.name,
      isActive: idx === activeCrewIndex,
      seesCat,
      seesCatHiss,
      seesTrackerPing,
      seesAlien
    };

    return crewDecorated;
  });
};

// Returns the decorated object for the active crew member.
export const activeCrewSelector = (state) => {
  const { activeCrewIndex } = state.actors;
  return crewListSelector(state)[activeCrewIndex];
};
