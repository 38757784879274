import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gameTick } from "~/actions/game";
import DoorIndicator from "~/components/door-indicator";
import VentIndicator from "~/components/vent-indicator";

const GamePanel = () => {
  const tooh = useSelector((s) => s.game.tooh);
  const dispatch = useDispatch();

  useEffect(() => {
    let ti = setInterval(() => {
      dispatch(gameTick());
    }, 1000);

    return () => {
      clearInterval(ti);
    };
  }, []);

  return (
    <div id="game-panel" className="flex-row">
      <span>TOOH: {tooh}</span>
      <div className="flex-row">
        <DoorIndicator />
        &nbsp;
        <VentIndicator />
      </div>
      <div></div>
    </div>
  );
};

export default GamePanel;
