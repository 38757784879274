import { setActorLocation, setActiveCrewIndex } from "~/state/actors";
import { resetGameState, tick } from "~/state/game";
import { rndArr } from "~/utils/rnd";
import AI from "./ai";
import { advanceActors } from "./actors";
import { playPingSounds } from "./ui";

// Init & start the game.
export const startGame = (scenarioType = 'short') => {
  return (dispatch, getState) => {
    // TODO: Reset crew inventory

    // TODO: Kill all except 3 crew (short game)

    // TODO: Kill one random crew member and put them in Mess Hall (full game)
    // TODO: Select android - Dallas, Ash, Parker or Kane (full game)

    // TODO: Spawn items in proper locations

    // TODO: Choose spawn time for Jones and the Alien

    // Choose start location for Jones and the Alien
    const npcs = getState().actors.actors.filter((a) => !a.isCrew).forEach((a) => {
      const locationId = rndArr(a.starts);
      dispatch(setActorLocation({ actorId: a.index, locationId }));
    });

    // Select first alive crew member
    const startingCrewIndex = getState().actors.actors.find((a) => a.isCrew && a.health > 0).index;
    dispatch(setActiveCrewIndex(startingCrewIndex));

    // Start the game

    dispatch(resetGameState());
  };
};

// Run once every second. Controls most of the game AI.
export const gameTick = () => {
  return (dispatch, getState) => {
    dispatch(tick());
    dispatch(advanceActors());
    dispatch(playPingSounds());

    // NPC AI
    const npcs = getState().actors.actors.filter((a) => !a.isCrew);
    npcs.forEach((a) => {
      dispatch(AI[a.aiType]());
    });
  };
};

