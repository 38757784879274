import { mixinNestedObjects } from "~/utils/structs";
import {
  IV_SPANNER1,
  IV_SPANNER2,
  IV_TRACKER1,
  IV_TRACKER2,
  IV_HARPOON,
  IV_INCINERATOR1,
  IV_INCINERATOR2,
  IV_INCINERATOR3,
  IV_CATBOX,
  IV_NET
} from "./items";

// Map
// TODO: add vent system

const D_UPPER = 'upper';
const D_MIDDLE = 'middle';
const D_LOWER = 'lower';

export const L_AIRLOCK1 = 'airlock1';
export const L_AIRLOCK2 = 'airlock2';
export const L_ARMORY = 'armory';
export const L_CARGO_POD1 = 'cargoPod1';
export const L_CARGO_POD2 = 'cargoPod2';
export const L_CARGO_POD3 = 'cargoPod3';
export const L_COMMAND_CENTER = 'commandCenter';
export const L_COMPUTER = 'computer';
export const L_CORRIDOR1 = 'corridor1';
export const L_CORRIDOR2 = 'corridor2';
export const L_CORRIDOR3 = 'corridor3';
export const L_CORRIDOR4 = 'corridor4';
export const L_CORRIDOR5 = 'corridor5';
export const L_CORRIDOR6 = 'corridor6';
export const L_CORRIDOR7 = 'corridor7';
export const L_CRYO_VAULT = 'cryoVault';
export const L_ENGINE1 = 'engine1';
export const L_ENGINE2 = 'engine2';
export const L_ENGINE3 = 'engine3';
export const L_ENGINEERING = 'engineering';
export const L_ENG_STORAGE = 'engStorage';
export const L_INFIRMARY = 'infirmary';
export const L_INF_STORES = 'infStores';
export const L_LABORATORY = 'laboratory';
export const L_LAB_STORES = 'labStores';
export const L_LIFE_SUPPORT = 'lifeSupport';
export const L_LIVING_QUARTERS = 'livingQuarters';
export const L_MESS = 'mess';
export const L_RECREATION = 'recreation';
export const L_SHUTTLE_BAY = 'shuttleBay';
export const L_SHUTTLE_STORAGE = 'shuttleStorage';
export const L_STORES1 = 'stores1';
export const L_STORES2 = 'stores2';
export const L_STORES3 = 'stores3';

const UPPER_DECK = mixinNestedObjects({
  [L_LIVING_QUARTERS]: {
    name: 'Living Qtrs.',
    roomConnections: [L_RECREATION, L_MESS],
    deckConnections: [L_CORRIDOR1]
  },
  [L_RECREATION]: {
    name: 'Rec. Area',
    roomConnections: [L_LIVING_QUARTERS, L_MESS]
  },
  [L_AIRLOCK1]: {
    name: 'Airlock #1',
    roomConnections: [L_CORRIDOR6]
  },
  [L_MESS]: {
    name: 'Mess Hall',
    roomConnections: [L_LIVING_QUARTERS, L_RECREATION, L_CORRIDOR6]
  },
  [L_AIRLOCK2]: {
    name: 'Airlock #2',
    roomConnections: [L_CORRIDOR6]
  },
  [L_CORRIDOR6]: {
    name: 'Corridor #6',
    roomConnections: [L_AIRLOCK1, L_AIRLOCK2, L_MESS, L_STORES3, L_COMPUTER]
  },
  [L_STORES2]: {
    name: 'Stores #2',
    roomConnections: [L_COMPUTER]
  },
  [L_STORES3]: {
    name: 'Stores #3',
    roomConnections: [L_CORRIDOR6],
    items: [IV_SPANNER1]
  },
  [L_COMPUTER]: {
    name: 'Computer',
    roomConnections: [L_CORRIDOR6, L_STORES2]
  }
}, () => ({ deck: D_UPPER }));

const MIDDLE_DECK = mixinNestedObjects({
  [L_COMMAND_CENTER]: {
    name: 'Command Ctr.',
    roomConnections: [L_CORRIDOR1],
    items: [IV_TRACKER1, IV_TRACKER2, IV_INCINERATOR1, IV_INCINERATOR2, IV_NET, IV_CATBOX]
  },
  [L_INFIRMARY]: {
    name: 'Infirmary',
    roomConnections: [L_CORRIDOR1]
  },
  [L_CORRIDOR1]: {
    name: 'Corridor #1',
    roomConnections: [L_COMMAND_CENTER, L_INFIRMARY, L_LABORATORY, L_CORRIDOR2],
    deckConnections: [L_LIVING_QUARTERS]
  },
  [L_LABORATORY]: {
    name: 'Laboratory',
    roomConnections: [L_CORRIDOR1]
  },
  [L_CORRIDOR2]: {
    name: 'Corridor #2',
    roomConnections: [L_CORRIDOR1, L_CORRIDOR3, L_CRYO_VAULT, L_CORRIDOR5],
    deckConnections: [L_CARGO_POD2]
  },
  [L_INF_STORES]: {
    name: 'Inf. Stores',
    roomConnections: [L_CORRIDOR3]
  },
  [L_CORRIDOR3]: {
    name: 'Corridor #3',
    roomConnections: [L_INF_STORES, L_ARMORY, L_ENGINE1, L_CORRIDOR4, L_CORRIDOR2]
  },
  [L_CRYO_VAULT]: {
    name: 'Cryo Vault',
    roomConnections: [L_CORRIDOR2]
  },
  [L_CORRIDOR5]: {
    name: 'Corridor #5',
    roomConnections: [L_CORRIDOR2, L_LAB_STORES, L_ENGINE3, L_STORES1, L_CORRIDOR4]
  },
  [L_LAB_STORES]: {
    name: 'Lab Stores',
    roomConnections: [L_CORRIDOR5]
  },
  [L_ARMORY]: {
    name: 'Armory',
    roomConnections: [L_CORRIDOR3]
  },
  [L_CORRIDOR4]: {
    name: 'Corridor #4',
    roomConnections: [L_CORRIDOR3, L_ENGINE2, L_CORRIDOR5]
  },
  [L_STORES1]: {
    name: 'Stores #1',
    roomConnections: [L_CORRIDOR5]
  },
  [L_ENGINE1]: {
    name: 'Engine #1',
    roomConnections: [L_CORRIDOR3]
  },
  [L_ENGINE2]: {
    name: 'Engine #2',
    roomConnections: [L_CORRIDOR4]
  },
  [L_ENGINE3]: {
    name: 'Engine #3',
    roomConnections: [L_CORRIDOR5]
  }
}, () => ({ deck: D_MIDDLE }));

const LOWER_DECK = mixinNestedObjects({
  [L_SHUTTLE_STORAGE]: {
    name: 'Shutt. Storage',
    roomConnections: [L_SHUTTLE_BAY]
  },
  [L_SHUTTLE_BAY]: {
    name: 'Shuttle Bay',
    roomConnections: [L_SHUTTLE_STORAGE, L_ENGINEERING, L_CARGO_POD2],
    items: [IV_HARPOON]
  },
  [L_ENGINEERING]: {
    name: 'Engineering',
    roomConnections: [L_SHUTTLE_BAY, L_ENG_STORAGE],
    items: [IV_INCINERATOR3]
  },
  [L_ENG_STORAGE]: {
    name: 'Eng. Storage',
    roomConnections: [L_ENGINEERING],
    items: [IV_SPANNER2]
  },
  [L_CARGO_POD1]: {
    name: 'Cargo Pod #1',
    roomConnections: [L_CARGO_POD2]
  },
  [L_CARGO_POD2]: {
    name: 'Cargo Pod #2',
    roomConnections: [L_SHUTTLE_BAY, L_CARGO_POD1, L_CARGO_POD3, L_CORRIDOR7],
    deckConnections: [L_CORRIDOR2]
  },
  [L_CARGO_POD3]: {
    name: 'Cargo Pod #3',
    roomConnections: [L_CARGO_POD2]
  },
  [L_CORRIDOR7]: {
    name: 'Corridor #7',
    roomConnections: [L_CARGO_POD2, L_LIFE_SUPPORT]
  },
  [L_LIFE_SUPPORT]: {
    name: 'Life Support',
    roomConnections: [L_CORRIDOR7]
  }
}, () => ({ deck: D_LOWER }));

export default {
  ...UPPER_DECK,
  ...MIDDLE_DECK,
  ...LOWER_DECK
};
