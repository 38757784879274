import { mixinCollectionIndexes } from "~/utils/structs";

import {
  L_COMPUTER,
  L_MESS,
  L_COMMAND_CENTER,
  L_INFIRMARY,
  L_ENGINEERING,
  L_LIVING_QUARTERS,
  L_RECREATION,
  L_STORES2,
  L_ENGINE1,
  L_ENGINE2,
  L_ENGINE3,
  L_CARGO_POD1,
  L_CARGO_POD3,
  L_CRYO_VAULT,
  L_ENG_STORAGE,
  L_LIFE_SUPPORT,
  L_SHUTTLE_STORAGE
} from "./map";

// Actors

export default mixinCollectionIndexes([
  // Crew
  {
    name: 'Dallas',
    location: L_COMPUTER,
    health: 0,
    will: 3,
    isCrew: true
  },
  {
    name: 'Kane',
    location: L_MESS,
    health: 0,
    will: 0,
    isCrew: true
  },
  {
    name: 'Ripley',
    location: L_COMMAND_CENTER,
    health: 3,
    will: 2,
    isCrew: true
  },
  {
    name: 'Ash',
    location: L_INFIRMARY,
    health: 0,
    will: 3,
    isCrew: true
  },
  {
    name: 'Lambert',
    location: L_COMMAND_CENTER,
    health: 3,
    will: 1,
    isCrew: true
  },
  {
    name: 'Parker',
    location: L_COMMAND_CENTER,
    health: 3,
    will: 3,
    isCrew: true
  },
  {
    name: 'Brett',
    location: L_ENGINEERING,
    health: 0,
    will: 2,
    isCrew: true
  },
  // Cat
  {
    name: 'Jones',
    location: null,
    health: 99,
    isCrew: false,
    isCat: true,
    isCaptured: false,
    capturedInItem: null,
    starts: [L_MESS, L_RECREATION, L_LIVING_QUARTERS, L_STORES2, L_ENGINE3, L_CRYO_VAULT, L_ENG_STORAGE, L_LIFE_SUPPORT],
    aiType: 'cat'
  },
  // Alien
  {
    name: 'Alien',
    moveSpeed: 3,
    location: null,
    health: 99,
    isCrew: false,
    isAlien: true,
    starts: [L_ENGINE1, L_ENGINE2, L_ENGINE3, L_CARGO_POD1, L_CARGO_POD3, L_SHUTTLE_STORAGE, L_ENG_STORAGE],
    aiType: 'alien'
  }
]);
