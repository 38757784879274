// Returns all actor objects in the given location.
export const actorsInLocationSelector = (state, locationId) => {
  const { actors } = state.actors;
  return actors.filter((a) => a.location === locationId);
};

// Returns the location (room) for the given actor index.
export const actorRoomSelector = (state, actorIdx) => {
  const { rooms } = state.map;
  const { location } = state.actors.actors[actorIdx];
  return rooms[location];
};

export const aliveCrewInLocationSelector = (state, location) => state.actors.actors.filter((a) => a.isCrew && a.health > 0 && a.location === location);
