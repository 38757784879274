const door = new URL("~/assets/sounds/door2.wav", import.meta.url);
const tracker = new URL("~/assets/sounds/tracker5.wav", import.meta.url);
const ambience = new URL("~/assets/sounds/ambience1.mp3", import.meta.url);
const hover = new URL("~/assets/sounds/click1.wav", import.meta.url);
const select = new URL("~/assets/sounds/click2.wav", import.meta.url);
const vent = new URL("~/assets/sounds/vent.wav", import.meta.url);
const alarm1 = new URL("~/assets/sounds/alarm1.wav", import.meta.url);
const alarm2 = new URL("~/assets/sounds/alarm2.wav", import.meta.url);

export default {
  door,
  tracker,
  ambience,
  hover,
  select,
  vent,
  alarm1,
  alarm2,
};
