import React from "react";
import { useSelector } from "react-redux";
import { activeCrewSelector } from "~/selectors/crew";

const MapDisplay = () => {
  const { location, seesAlien } = useSelector(activeCrewSelector);
  const { targetLocation, rooms } = useSelector((s) => s.map);
  const deck = targetLocation !== null ? rooms[targetLocation].deck : rooms[location].deck;

  return (
    <div id="map-display" data-floor={deck} data-alien-alert={seesAlien}>
      {seesAlien && <div id="map-alien-alert">DANGER - HOSTILE LIFEFORM</div>}
      {deck === rooms[location].deck && <div id="active-member-location" data-location={location}></div>}
      <div id="target-member-location" data-location={targetLocation}></div>
    </div>
  );
};

export default MapDisplay;
